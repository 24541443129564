/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import YoungJPG from '@assets/images/young.jpg';
import styled from '@emotion/styled';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Image from '~/components/atoms/Image/Image';
import {
  ImageContainer,
  Container,
  ContainerSmall,
  FlexContainerNull,
  List,
} from '~/utils/styles/utils';
import { useTranslationContext } from '~/pages/schlafmedizin-muenchen';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/sleep-medicine-munich';

const SleepMain: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();
  const { i18n } = useTranslation();

  return (
    <>
      <Container>
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('SleepHeading') || tEn('SleepHeading'),
            }}
          />
          <FlexContainerNull>
            <ImageContainer>
              <Image src={YoungJPG} alt="Bild Prof. Young" width={420} />
            </ImageContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('SleepContent1') || tEn('SleepContent1'),
                }}
              />
            </List>
          </FlexContainerNull>
          <FlexContainerNull>
            <List style={{ marginTop: '1.5rem' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('SleepContent2') || tEn('SleepContent2'),
                }}
              />
            </List>
            {i18n.language === 'de' ? (
              <Video>
                <iframe
                  width="400"
                  height="235"
                  src="https://www.youtube-nocookie.com/embed/YBrHl086XQk"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Video>
            ) : null}
          </FlexContainerNull>
        </ContainerSmall>
      </Container>
    </>
  );
};

const Video = styled.div`
  @media (min-width: 1200px) {
    margin-left: 3rem;
    margin-top: none;
    display: flex;
    align-items: center;
  }

  margin-top: 1rem;
`;

export default SleepMain;
