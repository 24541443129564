import React from 'react';
import NeuroPsychologyRoomJPG from '@assets/images/neuro-psychology-room.jpg';
import NeuroPsychologyRoomMobileJPG from '@assets/images/neuro-psychology-room-mobile.jpg';
// eslint-disable-next-line import/no-cycle
import SleepMain from '~/components/organisms/SleepMain/SleepMain';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

const SleepPage: React.FC = () => {
  return (
    <>
      <HeaderFooterLayout
        desktopHero={NeuroPsychologyRoomJPG}
        mobileHero={NeuroPsychologyRoomMobileJPG}
        altHero="Schlafmedizin Raum"
        enLink="/en/sleep-medicine-munich/"
        deLink="/schlafmedizin-muenchen/"
      >
        <SleepMain />
      </HeaderFooterLayout>
    </>
  );
};

export default SleepPage;
